import React from "react";
import { Link } from "@reach/router";
import cardcategory_data from "../../data/cardcategory_data.json";

const catgor = () => {
  return (
    <div className="row">
      {cardcategory_data.category_list.map((itm, idx) => {
        return (
          <div key={itm.name} className="col-md-2 col-sm-4 col-6 mb-3">
            <Link
              className="icon-box style-2 rounded"
              to={`/credit-cards/category/${itm.name.replace(/ /g, "-")}`}
            >
              <i className={"fa " + itm.icon}></i>
              <span>{itm.name}</span>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
export default catgor;
