import React from "react";

import cardcategory_data from "../../data/cardcategory_data.json";

import bank_data from "../../data/bank_data.json";
import Accordion from "react-bootstrap/Accordion";
export default function CreditCardSideFilters({
  selectedFilters,
  handleCheckboxChange,
  handleRemoveFilter,
}) {
  return (
    <div className="col-lg-3 col-md-12">
      {/* Categories Filter */}
      <div className="item_filter_group">
        <div className="de_form">
          <div className="de_checkbox">
            <input
              id="check_lounge_access"
              name="check_lounge_access"
              type="checkbox"
              value="Lounge Access"
              checked={selectedFilters.loungeAccess}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="check_lounge_access">Lounge Access</label>
          </div>
        </div>
      </div>

      <Accordion>
        <div className="item_filter_group">
          <Accordion.Toggle as="div" eventKey="0">
            <div role="button" className="d-flex">
              <h6>Select Categories</h6>
              <i className="fa fa-chevron-down px-2 py-1"></i>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="de_form">
              {cardcategory_data.category_list.map((itm, idx) => {
                const main_key = `check_cat_${idx}`;

                return (
                  <div key={itm.name} className="de_checkbox">
                    <input
                      id={main_key}
                      name={main_key}
                      type="checkbox"
                      value={itm.name}
                      checked={selectedFilters.categories.includes(itm.name)}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor={main_key}>{itm.name}</label>
                  </div>
                );
              })}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>

      <Accordion>
        <div className="item_filter_group">
          <Accordion.Toggle as="div" eventKey="0">
            <div role="button" className="d-flex">
              <h6>Card Issuer</h6>
              <i className="fa fa-chevron-down px-2 py-1"></i>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="de_form">
              {bank_data.bank_list.map((itm, idx) => {
                const main_key = `check_issuer_${idx}`;
                return (
                  <div
                    key={`sidefilter_${itm.name.toLowerCase()}`}
                    className="de_checkbox"
                  >
                    <input
                      id={main_key}
                      name={main_key}
                      type="checkbox"
                      value={itm.name.toLowerCase()}
                      checked={selectedFilters.issuers.includes(
                        itm.name.toLowerCase()
                      )}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor={main_key}>{itm.name}</label>
                  </div>
                );
              })}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>

      <Accordion>
        <div className="item_filter_group">
          <Accordion.Toggle as="div" eventKey="0">
            <div role="button" className="d-flex">
              <h6>Card Network</h6>
              <i className="fa fa-chevron-down px-2 py-1"></i>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div className="de_form">
              {bank_data.card_network.map((itm, idx) => {
                const main_key = `check_cardntwrk_${idx}`;
                return (
                  <div key={itm.name} className="de_checkbox">
                    <input
                      id={main_key}
                      name={main_key}
                      type="checkbox"
                      value={itm.name}
                      checked={selectedFilters.networks.includes(itm.name)}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor={main_key}>{itm.name}</label>
                  </div>
                );
              })}
            </div>
          </Accordion.Collapse>
        </div>
      </Accordion>

      {/* Debug Section with Bubble Tags 
      <div>
        <h5>Selected Filters:</h5>
        <div>
          {selectedFilters.categories.length > 0 && (
            <div>
              <strong>Categories:</strong>
              <div>
                {selectedFilters.categories.map((category) => (
                  <span
                    key={category}
                    className="filter-bubble"
                    onClick={() => handleRemoveFilter("categories", category)}
                  >
                    {category} <i className="fa fa-times-circle"></i>
                  </span>
                ))}
              </div>
            </div>
          )}

          {selectedFilters.issuers.length > 0 && (
            <div>
              <strong>Issuers:</strong>
              <div>
                {selectedFilters.issuers.map((issuer) => (
                  <span
                    key={issuer}
                    className="filter-bubble"
                    onClick={() => handleRemoveFilter("issuers", issuer)}
                  >
                    {issuer} <i className="fa fa-times-circle"></i>
                  </span>
                ))}
              </div>
            </div>
          )}

          {selectedFilters.networks.length > 0 && (
            <div>
              <strong>Networks:</strong>
              <div>
                {selectedFilters.networks.map((network) => (
                  <span
                    key={network}
                    className="filter-bubble"
                    onClick={() => handleRemoveFilter("networks", network)}
                  >
                    {network} <i className="fa fa-times-circle"></i>
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      */}
    </div>
  );
}
