import React from "react";
import bank_data from "../../data/bank_data.json";

import { useParams, navigate } from "@reach/router"; // Import from @reach/router

const BankList = () => {
  const handleBankFilterClick = (bank) => {
    navigate(`/credit-cards/bank/${bank.replace(/ /g, "-").toLowerCase()}`);
  };

  return (
    <div>
      <ol className="author_list">
        {bank_data.bank_list.map((itm, idx) => {
          return (
            <li key={itm.name} onClick={() => handleBankFilterClick(itm.name)}>
              <div className="author_list_pp">
                <span onClick={() => window.open("", "_self")}>
                  <img className="lazy" src={itm.imageSource} alt={itm.name} />
                  <i className="fa fa-check"></i>
                </span>
              </div>
              <div className="author_list_info">
                <span onClick={() => window.open("", "_self")}>{itm.name}</span>
                <span className="bot">{itm.cardIssued}</span>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
export default BankList;
