import React, { Component } from "react";
import styled from "styled-components";

export default class CCInfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: "January, 10, 2022",
      deadline1: "February, 10, 2022",
      deadline2: "February, 1, 2022",
      height: 0,
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }

  onImgLoad({ target: img }) {
    let currentHeight = this.state.height;
    if (currentHeight < img.offsetHeight) {
      this.setState({
        height: img.offsetHeight,
      });
    }
  }
  render() {
    const {
      index,
      card_name,
      product_image,
      joining_fee,
      annual_fee,
      welcome_benefits,
      rating,
    } = this.props;

    return (
      <div className="d-item">
        <div className="nft__item">
          {/* <div className="de_countdown">
          <Clock deadline={this.state.deadline} />
        </div>
        <div className="author_list_pp">
          <span onClick={() => window.open("/home1", "_self")}>
            <img className="lazy" src="./img/author/author-1.jpg" alt="" />
            <i className="fa fa-check"></i>
          </span>
        </div> */}
          <div
            className="nft__item_wrap"
            // style={{ height: `${this.state.height}px` }}
          >
            <div className="outer">
              <span>
                <img
                  //  "./img/banklogo/hdfcbank.jpg"
                  src={`https://media.banksathi.com/${product_image}`}
                  className="lazy nft__item_preview"
                  onLoad={this.onImgLoad}
                  alt=""
                />
              </span>
            </div>
          </div>
          <div className="nft__item_title">
            <span onClick={() => window.open("/#", "_self")}>
              <h4>{card_name}</h4>
            </span>
            <div className="nft__item_info">
              {welcome_benefits.replace(/'/g, "").split(",").join(", ")}
            </div>
            <div className="nft__item_details">
              <div>
                <span>Joining Fee:</span>{" "}
                {joining_fee == 0 ? "Free" : `₹${joining_fee} + GST`}
              </div>
              <div>
                <span>Annual Fee:</span>{" "}
                {annual_fee == 0 ? "Free" : `₹${annual_fee} + GST`}
              </div>
            </div>

            <div className="nft__item_action">
              <span onClick={() => window.open("/#", "_self")}>View more</span>
            </div>
            <div className="nft__item_like">
              <i className="fa fa-heart"></i>
              <span>{rating}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
