import React from "react";
import SliderMain from "../components/SliderMain";
import FeatureBox from "../components/FeatureBox";
import CarouselCollection from "../components/CarouselCollection";
import CarouselNew from "../components/CarouselNew";
import BankList from "../components/BankList";
import Catgor from "../components/Catgor";
import Footer from "../components/footer";
import { useParams, navigate } from "@reach/router"; // Import from @reach/router

const home = () => (
  <div>
    <section
      className="jumbotron breadcumb no-bg h-vh"
      style={{ backgroundImage: `url(${"./img/bg-shape-1.jpg"})` }}
    >
      <SliderMain />
    </section>

    {/* <section className='container no-top no-bottom'>
        <FeatureBox/>
      </section> */}

    <section className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Top Credit Card Categories </h2>
            <div className="small-border"></div>
          </div>
        </div>
      </div>
      <Catgor />
    </section>

    <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Most Popular Credit Cards in India</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <CarouselNew />
        </div>
      </div>
    </section>

    {/* <section className='container no-bottom'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='text-center'>
              <h2>CREDIT CARD BY CREDIT ISSUER</h2>
              <div className="small-border"></div>
            </div>
          </div>
          <div className='col-lg-12'>
            <CarouselCollection/>
          </div>
        </div>
      </section> */}

    <section className="container no-bottom">
      <div className="row">
        <div className="col-lg-12">
          <div className="text-center">
            <h2>Top Credit Card by Issuer</h2>
            <div className="small-border"></div>
          </div>
        </div>
        <div className="col-lg-12">
          <BankList />
        </div>
      </div>
    </section>

    <Footer />
  </div>
);
export default home;
