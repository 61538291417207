import React from "react";
import Select from "react-select";
const customStyles = {
  option: (base, state) => ({
    ...base,
    background: "#fff",
    color: "#333",
    borderRadius: state.isFocused ? "0" : 0,
    "&:hover": {
      background: "#eee",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  control: (base, state) => ({
    ...base,
    padding: 2,
  }),
};

const options1 = [
  { label: "Credit Score (Low to High)", value: "credit_score_asc" },
  { label: "Credit Score (High to Low)", value: "credit_score_desc" },
  { label: "Annual Fee (Low to High)", value: "annual_fee_asc" },
  { label: "Annual Fee (High to Low)", value: "annual_fee_desc" },
  { label: "APR (Low to High)", value: "apr_asc" },
  { label: "APR (High to Low)", value: "apr_desc" },
];

const options2 = [
  { value: "All Items", label: "All Items" },
  { value: "Single Items", label: "Single Items" },
  { value: "Bundles", label: "Bundles" },
];
export default function CreditCardTopFilters({
  selectedFilters,
  handleSortChange,
  handleRemoveFilter,
}) {
  return (
    <div>
      <div className="items_filter">
        <form
          className="row form-dark"
          id="form_quick_search"
          name="form_quick_search"
        >
          <div className="col">
            <input
              className="form-control"
              id="name_1"
              name="name_1"
              placeholder="search item here..."
              type="text"
            />{" "}
            <button id="btn-submit">
              <i className="fa fa-search bg-color-secondary"></i>
            </button>
            <div className="clearfix"></div>
          </div>
        </form>
        <div className="dropdownSelect one">
          <Select
            styles={customStyles}
            defaultValue={options1[0]}
            options={options1}
            onChange={handleSortChange}
          />
        </div>
        {/* <div className="dropdownSelect two">
          <Select
            styles={customStyles}
            defaultValue={options1[0]}
            options={options1}
          />
        </div>
        <div className="dropdownSelect three">
          <Select
            styles={customStyles}
            defaultValue={options2[0]}
            options={options2}
          />
        </div> */}
      </div>

      {/* Display Applied Filters */}
      {Object.values(selectedFilters).some((filters) => filters.length > 0) && (
        <div className="applied-filters">
          <h6>Applied Filters:</h6>
          <div className="filters-container">
            {["categories", "issuers", "networks"].map((type) => {
              return selectedFilters[type].length > 0 ? (
                <div key={type} className="filter-group">
                  {selectedFilters[type].map((filter) => (
                    <div key={filter} className="filter-tag">
                      <span>{filter}</span>
                      <i
                        className="fa fa-times"
                        onClick={() => handleRemoveFilter(type, filter)}
                      ></i>
                    </div>
                  ))}
                </div>
              ) : null;
            })}
          </div>
        </div>
      )}
    </div>
  );
}
