import React, { useEffect } from "react";
import Clock from "../components/Clock";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import { useParams, navigate } from "@reach/router"; // Import from @reach/router
import creditcard_data from "../../data/creditcard_data.json";
import { useState } from "react";
import StarRating from "../components/StarRating";
import { Helmet } from "react-helmet-async";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const Colection = function () {
  const { cardName } = useParams(); // Get the bank name from URL params
  const [cardInfo, setCardInfo] = useState({
    product_id: 261,
    category_id: 3,
    card_name: "Airtel Axis Bank Credit Card",
    product_details: null,
    features:
      "<ul>\r\n\t<li>Enjoy the amazing 10% cashback on Zomato and Swiggy</li>\r\n\t<li>Get 4 complimentary lounge visits per year.</li>\r\n\t<li>Eran 10% cashback on utility bill payments made via Airtel Thanks App.</li>\r\n\t<li>25% Cashback on Airtel Mobile, Brodband, Wifi and DTH bil payment through Airtel thanks App,</li>\r\n</ul>",
    welcome_offer:
      "<ul>\r\n\t<li>Get an Amazon eVoucher of INR 500 on the first transaction made within 30 days.&nbsp;</li>\r\n</ul>",
    lounge_access: "1",
    apr: 52.86,
    joining_fee: 500.0,
    itr: 360000.0,
    max_age: 70,
    min_age: 18,
    annual_fee: 500.0,
    product_image: "products/Airtel-Axis-Bank-Credit-Card.jpeg",
    welcome_benefits: "'Rewards','Cashback','Travel'",
    min_credit_score: 700,
    max_credit_score: 900,
    card_network: "Visa",
    salary: 25000.0,
    rating: 4.3,
    rating_header: "Advice from our Experts",
    rating_details:
      "Known for offering optimal rewards, the Axis Bank Airtel Credit Card is a standout choice. With up to 25% cashback on Airtel services, 10% cashback on utility bills, and additional partner brand benefits, it's the go-to option for those seeking travel and cashback advantages. Complimentary lounge access and dining discounts add to its appeal.",
    rating_based_text: null,
    bank_name: "AXIS BANK",
    bank_image:
      "advisors/products/logo/zOWHVmXXEClIXIHpIBVr7q4zab3YWvluKj2eFKqU.png",
    apply_url: "leads/airtel",
    url_slug: "credit-cards/axis-bank/airtel",
    sort_order: 2295,
    best_of: "",
    status: "1",
    meta_title: "Airtel Axis Bank Credit Card - Review, Benefits & Apply",
    meta_url: "https://www.banksathi.com/credit-cards/axis-bank/airtel",
    meta_description:
      "Airtel Axis Bank Credit Card Online - Check all details, reviews, rating, eligibility criteria, features, charges & offers of Axis Bank Airtel Credit Card.",
    meta_robots: "index,follow",
    og_title: "Airtel Axis Bank Credit Card - Review, Benefits & Apply",
    og_type: "website",
    og_url: "https://www.banksathi.com/credit-cards/axis-bank/airtel",
    og_description:
      "Airtel Axis Bank Credit Card Online - Check all details, reviews, rating, eligibility criteria, features, charges & offers of Axis Bank Airtel Credit Card.",
    og_image: "products/logo/gYXJDSZqeGm0wFRGfoKwZFQDBI4v9u0JiEjURhuo.png",
    og_image_alt: "Airtel Axis Bank Credit Card",
    og_image_type: "PNG",
    og_site_name: "banksathi",
    twitter_card: "summary_large_image",
    twitter_site: "@banksathi1",
    twitter_title: "Airtel Axis Bank Credit Card - Review, Benefits & Apply",
    twitter_description:
      "Airtel Axis Bank Credit Card Online - Check all details, reviews, rating, eligibility criteria, features, charges & offers of Axis Bank Airtel Credit Card.",
    twitter_image: "products/logo/HHuzgVPW94LMQMAkwMPDqruz908VtjYK6lGUwMtt.png",
    twitter_img_alt: "Airtel Axis Bank Credit Card",
    foreign_txn_fee: "3.5",
    fraud_liability: "Zero Lost Liability Cover",
    video_url: null,
    product_lead_url_param: "9AGFlfZvubIqzuz05L5Uvw==",
    publisher_name: "Pardeep Saini",
    published_time: "2024-10-14 18:38:39",
    paragraph:
      "For the seekers of cashback rewards with each spending and the ones who caught the travel bug, this one is for you! Get the best Axis Bank Travel credit in India by paying a nominal joining fee of ₹500 to avail the great benefits of this credit card. As a welcome benefit, get an Amazon e-voucher worth ₹500 after your first transaction within 30 days of card activation. Additionally, enjoy 4 complimentary lounge visits per year, a 10% cashback on buying from either Zomato, Swiggy, or BigBasket and on the payment of utility bills made via the Airtel Thanks app, and an amazing milestone benefit that will renounce your annual membership fee.",
    direct_campaign_url: null,
    is_apply_now: true,
  });

  useEffect(() => {
    console.log("Looking for card ", cardName);
    setCardInfo(
      creditcard_data.product_list.find((itm) =>
        itm.card_name
          .toLowerCase()
          .includes(cardName.replace(/-/g, " ").toLowerCase())
      )
    );
  }, [cardName]);

  const [openMenu, setOpenMenu] = React.useState(true);
  const [openMenu1, setOpenMenu1] = React.useState(false);
  const handleBtnClick = () => {
    setOpenMenu(!openMenu);
    setOpenMenu1(false);
    document.getElementById("Mainbtn").classList.add("active");
    document.getElementById("Mainbtn1").classList.remove("active");
  };
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
    setOpenMenu(false);
    document.getElementById("Mainbtn1").classList.add("active");
    document.getElementById("Mainbtn").classList.remove("active");
  };
  return (
    <div>
      <GlobalStyles />
      <Helmet>
        {/* Standard Meta */}
        <title>{cardInfo.meta_title}</title>
        <meta name="description" content={cardInfo.meta_description} />
        <meta name="robots" content={cardInfo.meta_robots} />
        <link rel="canonical" href={cardInfo.meta_url} />

        {/* Open Graph Meta */}
        <meta property="og:title" content={cardInfo.og_title} />
        <meta property="og:type" content={cardInfo.og_type} />
        <meta property="og:url" content={cardInfo.og_url} />
        <meta property="og:description" content={cardInfo.og_description} />
        {/* <meta property="og:image" content={cardInfo.og_image} /> */}
        <meta property="og:image:alt" content={cardInfo.og_image_alt} />
        <meta property="og:image:type" content={cardInfo.og_image_type} />
        <meta property="og:site_name" content={cardInfo.og_site_name} />

        {/* Twitter Meta */}
        <meta name="twitter:card" content={cardInfo.twitter_card} />
        <meta name="twitter:site" content={cardInfo.twitter_site} />
        <meta name="twitter:title" content={cardInfo.twitter_title} />
        <meta
          name="twitter:description"
          content={cardInfo.twitter_description}
        />
        {/* <meta name="twitter:image" content={cardInfo.twitter_image} /> */}
        <meta name="twitter:image:alt" content={cardInfo.twitter_img_alt} />
      </Helmet>

      <section className="container">
        <div className="row mt-md-5 pt-md-4">
          <div className="item_info_left col-3 d-none d-md-block">
            <div role="button">Card Details</div>
            <div role="button">Expert Review</div>
            <div role="button">Eligibility</div>
          </div>
          <div className="col-md-9 item_info_main">
            <div className="">
              <div className="item_info row">
                <div className="col-lg-4 card-container">
                  <img
                    src={`https://media.banksathi.com/${cardInfo.product_image}`}
                    className="img-fluid img-rounded mb-sm-30 credit-card"
                    alt=""
                  />
                </div>
                <div className="col-lg-8">
                  {/* Auctions ends in
                  <div className="de_countdown">
                    <Clock deadline="December, 30, 2021" />
                  </div> */}
                  <h2>{cardInfo.card_name}</h2>
                  <StarRating className="mb-3" rating={cardInfo.rating} />
                  <div className="item_info_counts">
                    {cardInfo.welcome_benefits
                      .replace(/'/g, "")
                      .split(",")
                      .map((itm) => (
                        <div key={`category_${itm}`} className="item_info_type">
                          <i className="fa fa-heart"></i>
                          {itm}
                        </div>
                      ))}
                  </div>
                </div>
                <div className="nft__item_feeinfo mt-4">
                  <div>
                    <div>
                      <span>Joining Fee:</span>{" "}
                      {cardInfo.joining_fee == 0
                        ? "Free"
                        : `₹${cardInfo.joining_fee} + GST`}
                    </div>
                    <div>
                      <span>Annual Fee:</span>{" "}
                      {cardInfo.annual_fee == 0
                        ? "Free"
                        : `₹${cardInfo.annual_fee} + GST`}
                    </div>
                    <div>
                      <span>Foreign Transaction Fee:</span>{" "}
                      {cardInfo.foreign_txn_fee == 0
                        ? "Free"
                        : `₹${cardInfo.foreign_txn_fee} + GST`}
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>Maximum Reward Rate:</span>{" "}
                      {`₹${cardInfo.apr}% annual percentage rate`}
                    </div>
                    <div>
                      <span>Lounge Access:</span>{" "}
                      {cardInfo.lounge_access === "1" ? "Yes" : "No"}
                    </div>
                  </div>{" "}
                  <div>
                    <div>
                      <span>Min Credit Score:</span> {cardInfo.min_credit_score}
                    </div>
                    <div>
                      <span>Minimum Salary:</span> {`₹${cardInfo.salary}`}
                    </div>
                    <div>
                      <span>Minimum Business Income:</span> {`₹${cardInfo.itr}`}
                    </div>
                  </div>
                </div>
                <hr className="hr my-3" />
                <p dangerouslySetInnerHTML={{ __html: cardInfo.paragraph }} />

                <div className="nft__item_moreinfo">
                  <div>
                    <h4>Features</h4>
                    <div
                      dangerouslySetInnerHTML={{ __html: cardInfo.features }}
                    />
                  </div>
                  <div>
                    <h4>Welcome Offer</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: cardInfo.welcome_offer,
                      }}
                    />
                  </div>
                </div>
                {/* <div className="spacer-40"></div>
                <div className="de_tab">
                  <ul className="de_nav">
                    <li id="Mainbtn" className="active">
                      <span onClick={handleBtnClick}>Bids</span>
                    </li>
                    <li id="Mainbtn1" className="">
                      <span onClick={handleBtnClick1}>History</span>
                    </li>
                  </ul>

                  <div className="de_tab_content">
                    {openMenu && (
                      <div className="tab-1 onStep fadeIn">
                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-1.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid accepted <b>0.005 ETH</b>
                            <span>
                              by <b>Monica Lucas</b> at 6/15/2021, 3:20 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-2.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.005 ETH</b>
                            <span>
                              by <b>Mamie Barnett</b> at 6/14/2021, 5:40 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-3.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.004 ETH</b>
                            <span>
                              by <b>Nicholas Daniels</b> at 6/13/2021, 5:03 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-4.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.003 ETH</b>
                            <span>
                              by <b>Lori Hart</b> at 6/12/2021, 12:57 AM
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {openMenu1 && (
                      <div className="tab-2 onStep fadeIn">
                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-5.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.005 ETH</b>
                            <span>
                              by <b>Jimmy Wright</b> at 6/14/2021, 6:40 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-1.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid accepted <b>0.005 ETH</b>
                            <span>
                              by <b>Monica Lucas</b> at 6/15/2021, 3:20 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-2.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.005 ETH</b>
                            <span>
                              by <b>Mamie Barnett</b> at 6/14/2021, 5:40 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-3.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.004 ETH</b>
                            <span>
                              by <b>Nicholas Daniels</b> at 6/13/2021, 5:03 AM
                            </span>
                          </div>
                        </div>

                        <div className="p_list">
                          <div className="p_list_pp">
                            <span>
                              <img
                                className="lazy"
                                src="./img/author/author-4.jpg"
                                alt=""
                              />
                              <i className="fa fa-check"></i>
                            </span>
                          </div>
                          <div className="p_list_info">
                            Bid <b>0.003 ETH</b>
                            <span>
                              by <b>Lori Hart</b> at 6/12/2021, 12:57 AM
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Colection;
