import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useParams, navigate } from "@reach/router"; // Import from @reach/router

import StarRating from "./StarRating";

export default class CCListView extends Component {
  constructor(props) {
    super(props);
    this.cardData = props.cardData;
    this.state = {
      card_list: this.cardData.slice(0, 8),
      height: 0,
    };
    this.onImgLoad = this.onImgLoad.bind(this);
  }
  loadMore = () => {
    let nftState = this.state.card_list;
    let start = nftState.length;
    let end = nftState.length + 4;
    this.setState({
      nfts: [...nftState, ...this.cardData.slice(start, end)],
    });
  };

  onImgLoad({ target: img }) {
    let currentHeight = this.state.height;
    if (currentHeight < img.offsetHeight) {
      this.setState({
        height: img.offsetHeight,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the cardData prop has changed
    if (prevProps.cardData !== this.props.cardData) {
      // If cardData changed, update the state with the new filtered data (first 8 items)
      this.setState({
        card_list: this.props.cardData.slice(0, 8),
      });
    }
  }

  render() {
    // console.log("this.state.card_list", this.props.cardData);

    return (
      <div className="row">
        {this.state.card_list.map((item, index) => (
          <CardComponent {...item} key={index} />
        ))}
        {this.state.card_list.length !== this.cardData.length && (
          <div className="col-lg-12">
            <div className="spacer-single"></div>
            <span
              onClick={() => this.loadMore()}
              className="btn-main lead m-auto"
            >
              Load More
            </span>
          </div>
        )}
      </div>
    );
  }
}

const CardComponent = (props) => {
  const {
    index,
    card_name,
    product_image,
    joining_fee,
    annual_fee,
    welcome_benefits,
    rating,
    min_credit_score,
    features,
    welcome_offer,
    lounge_access,
  } = props;

  const handleBtnClick = () => {
    navigate(`/credit-cards/${card_name.replace(/ /g, "-")}`);
  };
  return (
    <div className="d-item">
      <div className="nft__item">
        <div className="nft__item_wrap row ">
          <div className="col-md-12 col-lg-5">
            <div className="outer ">
              <span className="card-container">
                <img
                  //  "./img/banklogo/hdfcbank.jpg"
                  src={`https://media.banksathi.com/${product_image}`}
                  className="lazy nft__item_preview credit-card"
                  // onLoad={this.onImgLoad}
                  role="button"
                  onClick={handleBtnClick}
                  alt=""
                />
              </span>
            </div>
          </div>
          <div
            role="text"
            className="col-md-12 col-lg-7 nft__item_title text-left"
          >
            <h3 role="button" onClick={handleBtnClick}>
              {card_name} <i className="fa-share-square-o fa"></i>
            </h3>
            <StarRating rating={rating} />
            <div className="nft__item_info" role="text">
              {welcome_benefits.replace(/'/g, "").split(",").join(", ")}
            </div>

            <div className="nft__item_feeinfo">
              <div>
                <span>Joining Fee:</span>{" "}
                {joining_fee == 0 ? "Free" : `₹${joining_fee} + GST`}
              </div>
              <div>
                <span>Annual Fee:</span>{" "}
                {annual_fee == 0 ? "Free" : `₹${annual_fee} + GST`}
              </div>
              <div>
                <span>Min Credit Score:</span> {min_credit_score}
              </div>
            </div>
          </div>
        </div>

        <Accordion>
          <Accordion.Toggle as="div" eventKey="0">
            <div className="nft__item_action">
              <span>View more</span>
            </div>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <div className="nft__item_moreinfo">
              <div>
                <h4>Features</h4>
                <div dangerouslySetInnerHTML={{ __html: features }} />
              </div>
              <div>
                <h4>Welcome Offer</h4>
                <div dangerouslySetInnerHTML={{ __html: welcome_offer }} />
              </div>
              <div role="button" className="nft__item_actn">
                View full details
              </div>
            </div>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </div>
  );
};
