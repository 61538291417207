import React from "react";

const StarRating = ({ rating, className }) => {
  const maxStars = 5; // Total stars in the rating system
  const fullStars = Math.floor(rating); // Full stars
  const halfStar = rating % 1 >= 0.5 ? 1 : 0; // Check if there's a half star
  const emptyStars = maxStars - fullStars - halfStar; // Remaining empty stars

  return (
    <div className={`star-rating ${className}`}>
      <span>
        {rating}/{maxStars}
      </span>

      {Array(fullStars)
        .fill()
        .map((_, index) => (
          <i key={`full-${index}`} className="fa fa-star"></i>
        ))}
      {/* Render half star if applicable */}
      {halfStar === 1 && <i className="fa fa-star-half-full"></i>}
      {/* Render empty stars */}
      {Array(emptyStars)
        .fill()
        .map((_, index) => (
          <i key={`empty-${index}`} className="fa fa-star-o"></i>
        ))}
    </div>
  );
};

export default StarRating;
