import React from "react";
import Footer from "../components/footer";
import { createGlobalStyle } from "styled-components";
import CreditCardSideFilters from "./CreditCardSideFilters";
import creditcard_data from "../../data/creditcard_data.json";

import { useState } from "react";
import CCListView from "../components/CCListView";
import CreditCardTopFilters from "./CreditCardTopFilters";
import { useParams, navigate } from "@reach/router"; // Import from @reach/router

const GlobalStyles = createGlobalStyle`
  .navbar {
    border-bottom: solid 1px rgba(255, 255, 255, .1) !important;
  }
`;

const CreditCardExplorePage = () => {
  const { bankName, categoryName } = useParams(); // Get the bank name from URL params
  console.log("Prams", bankName, categoryName);
  const [selectedFilters, setSelectedFilters] = useState({
    categories: categoryName ? [categoryName.replace(/-/g, " ")] : [],
    issuers: bankName ? [bankName.replace(/-/g, " ")] : [], // Pre-select the bank filter if URL contains a bank name
    networks: [],
    loungeAccess: false,
  });
  const [sortOption, setSortOption] = useState("Default");

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;

    if (!name) return; // Skip if name is not defined

    setSelectedFilters((prevState) => {
      const updatedFilters = { ...prevState };

      // Handle category checkboxes
      if (name.includes("check_cat_")) {
        if (checked) {
          updatedFilters.categories.push(value);
        } else {
          updatedFilters.categories = updatedFilters.categories.filter(
            (item) => item !== value
          );
        }
      }

      // Handle issuer checkboxes
      else if (name.includes("check_issuer_")) {
        if (checked) {
          updatedFilters.issuers.push(value);
        } else {
          updatedFilters.issuers = updatedFilters.issuers.filter(
            (item) => item !== value
          );
        }
      }

      // Handle card network checkboxes
      else if (name.includes("check_cardntwrk_")) {
        if (checked) {
          updatedFilters.networks.push(value);
        } else {
          updatedFilters.networks = updatedFilters.networks.filter(
            (item) => item !== value
          );
        }
      }

      // Handle lounge access checkbox
      else if (name === "check_lounge_access") {
        updatedFilters.loungeAccess = checked;
      }

      return updatedFilters;
    });
  };

  const handleRemoveFilter = (filterType, value) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      updatedFilters[filterType] = updatedFilters[filterType].filter(
        (item) => item !== value
      );
      return updatedFilters;
    });
  };

  const filterCards = (cardData) => {
    // Log the selected filters for debugging
    console.log("Selected Filters: ", selectedFilters);

    // If no filters are applied, return the original card data
    if (
      !selectedFilters ||
      Object.keys(selectedFilters).length === 0 ||
      Object.values(selectedFilters).every((value) => {
        if (Array.isArray(value)) {
          // Check if the array is empty
          return value.length === 0;
        }
        // Check if the value is null or false (for booleans like loungeAccess)
        return value === null || value === false;
      })
    ) {
      console.log("No filters applied, returning original card data");
      return cardData;
    }

    // Log the length of the original data
    console.log(`Original card data length: ${cardData.length}`);

    return cardData.filter((card) => {
      // Category matching (searching in `welcome_offer` as an example)
      const matchesCategory =
        selectedFilters.categories.length === 0 ||
        selectedFilters.categories.some((category) => {
          let match = false;

          // Special case for "LifeTime free"
          if (category === "LifeTime Free") {
            match = card.annual_fee <= 1;
          } else {
            // Normal category matching
            match =
              card.welcome_benefits &&
              card.welcome_benefits
                .toLowerCase()
                .includes(category.toLowerCase());
          }

          // Optionally log for debugging
          // console.log(
          //   `Checking category "${category}" in card "${card.card_name}": ${match}`
          // );

          return match;
        });

      // Issuer matching (searching in `bank_name`)
      const matchesIssuer =
        selectedFilters.issuers.length == 0 ||
        selectedFilters.issuers.some((issuer) => {
          const match =
            card.bank_name &&
            card.bank_name.toLowerCase().includes(issuer.toLowerCase());
          // console.log(
          //   `Checking issuer "${issuer}" in card "${card.card_name}": ${match}`
          // );
          return match;
        });

      // Network matching (searching in `card_network`)
      const matchesNetwork =
        selectedFilters.networks.length == 0 ||
        selectedFilters.networks.some((network) => {
          const match =
            card.card_network &&
            card.card_network.toLowerCase().includes(network.toLowerCase());
          // console.log(
          //   `Checking network "${network}" in card "${card.card_name}": ${match}`
          // );
          return match;
        });

      // Lounge Access matching (searching in `lounge_access`)
      const matchesLoungeAccess = selectedFilters.loungeAccess
        ? card.lounge_access == "1"
        : true;
      // console.log(
      //   `Checking lounge access "${selectedFilters.loungeAccess}" in card "${card.card_name}": ${matchesLoungeAccess}`
      // );

      // Log the card and whether it passed all filters
      const isMatch =
        matchesCategory &&
        matchesIssuer &&
        matchesNetwork &&
        matchesLoungeAccess;
      // console.log(`Card "${card.card_name}" matches filters: ${isMatch}`);

      // Return true if all conditions match
      return isMatch;
    });
  };

  const handleSortChange = (selectedOption) => {
    setSortOption(selectedOption.value);
  };

  const sortCards = (cards, sortBy, order) => {
    console.log(sortBy, order);
    return cards.sort((a, b) => {
      let valueA, valueB;

      switch (sortBy) {
        case "credit_score":
          valueA = a.min_credit_score;
          valueB = b.min_credit_score;
          break;
        case "annual_fee":
          valueA = a.annual_fee;
          valueB = b.annual_fee;
          break;
        case "apr":
          valueA = a.apr;
          valueB = b.apr;
          break;
        default:
          return cards;
      }

      // Compare values for sorting order
      if (order === "asc") {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    });
  };

  const cardsToShow = [
    ...sortCards(
      filterCards(creditcard_data.product_list),
      sortOption.split("_")[0], // sortBy (e.g., "credit_score")
      sortOption.split("_")[1] === "asc" ? "asc" : "desc" // order (asc or desc)
    ),
  ];

  return (
    <div>
      <GlobalStyles />
      {/* <section className='jumbotron breadcumb no-bg' style={{backgroundImage: `url(${'./img/background/subheader.jpg'})`}}>
    <div className='mainbreadcumb'>
      <div className='container'>
        <div className='row m-10-hor'>
          <div className='col-12'>
            <h1 className='text-center'>Explore</h1>
          </div>
        </div>
      </div>
    </div>
  </section> */}

      <section className="container">
        <div className="row">
          <div className="spacer-double"></div>

          <div className="col-lg-12">
            <CreditCardTopFilters
              selectedFilters={selectedFilters}
              handleRemoveFilter={handleRemoveFilter}
              handleCheckboxChange={handleCheckboxChange}
              handleSortChange={handleSortChange}
            />
          </div>

          <CreditCardSideFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            handleRemoveFilter={handleRemoveFilter}
            handleCheckboxChange={handleCheckboxChange}
          />

          <div className="col-md-9">
            <CCListView cardData={cardsToShow} />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default CreditCardExplorePage;
