import React from "react";
import { Link } from "@reach/router";
import bank_data from "../../data/bank_data.json";
import cardcategory_data from "../../data/cardcategory_data.json";

const footer = () => (
  <footer className="footer-light">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
            <h5>Browse by Category</h5>
            <ul>
              {cardcategory_data.category_list.slice(0, 6).map((cate) => (
                <li key={`footer_${cate.name}`}>
                  <Link
                    to={`/credit-cards/category/${cate.name
                      .replace(/ /g, "-")
                      .toLowerCase()}`}
                  >
                    {cate.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
            <h5>Browse by Bank</h5>
            <ul>
              {bank_data.bank_list.slice(0, 6).map((bank) => (
                <li key={`footer_${bank.name}`}>
                  <Link
                    to={`/credit-cards/bank/${bank.name
                      .replace(/ /g, "-")
                      .toLowerCase()}`}
                  >
                    {bank.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
            <h5>Resource</h5>
            <ul>
              <li>
                <Link to="">Credit Card Recommender</Link>
              </li>
              {/* <li>
                <Link to="">Documentation</Link>
              </li>
              <li>
                <Link to="">Brand Assets</Link>
              </li>
              <li>
                <Link to="">Blog</Link>
              </li>
              <li>
                <Link to="">Forum</Link>
              </li>
              <li>
                <Link to="">Mailing List</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-xs-1">
          <div className="widget">
            <h5>Newsletter</h5>
            <p>
              Signup for our newsletter to get the latest news in your inbox.
            </p>
            <form
              action="#"
              className="row form-dark"
              id="form_subscribe"
              method="post"
              name="form_subscribe"
            >
              <div className="col text-center">
                <input
                  className="form-control"
                  id="txt_subscribe"
                  name="txt_subscribe"
                  placeholder="enter your email"
                  type="text"
                />
                <Link to="" id="btn-subscribe">
                  <i className="arrow_right bg-color-secondary"></i>
                </Link>
                <div className="clearfix"></div>
              </div>
            </form>
            <div className="spacer-10"></div>
            <small>Your email is safe with us. We don't spam.</small>
          </div>
        </div>
      </div>
    </div>
    <div className="subfooter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <span onClick={() => window.open("", "_self")}>
                  <img
                    alt=""
                    className="f-logo d-1"
                    src="./img/home/logo.png"
                  />
                  <img
                    alt=""
                    className="f-logo d-3"
                    src="./img/home/logo.png"
                  />
                  <span className="copy">
                    &copy; Copyright 2024 - TrueCards
                  </span>
                </span>
              </div>
              <div className="de-flex-col">
                <div className="social-icons">
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-facebook fa-lg"></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-twitter fa-lg"></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-linkedin fa-lg"></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-pinterest fa-lg"></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-rss fa-lg"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default footer;
